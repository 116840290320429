import React, { useEffect, useState, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Chargement from './assets/images/LOADER_SITEWEB.webp';
import { ParallaxProvider } from 'react-scroll-parallax';


const Home = lazy(() => import('./pages/Home'));
const Form = lazy(() => import('./pages/Form'));
const Pdf = lazy(() => import('./pages/Pdf'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Paiement = lazy(() => import('./pages/Paiement'));

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
    
  }, []);
  

  

  return (
    <div className="app">
      {isLoading ? (
        <div className="loader-container">
          <img width={150} height={150} src={Chargement} alt="rat face loader" />
        </div>
      ) : (
        <BrowserRouter>
         <ParallaxProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/form_mor_0" element={<Form />} />
            <Route path='/Paiement/:buttonId' element={<Paiement/>} />
            <Route path="/Pdf" element={<Pdf />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          </ParallaxProvider>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
